<template lang="pug">
div#ido-pei
  div.banner: img(:src="plant.p_img")
  article.small
    p {{plant.p_describe}} <span class="txt gray">[{{plant.p_name}}]</span>
    div.right: span.txt.green.bold(@click="_showModal" v-if="canAnswer") 我来浇灌
  question(title="答题浇灌" :show="showModal" :close="_showModal" @commit="_submit" :question="qdata.question" :rightKey="qdata.rightKey" :items="qdata.items")
</template>
<script>
import question from '@/components/modal/question'
export default {
  components: {question},
  data(){return {
    showModal: false,
    showBtn: false,
    canAnswer: true,
    plant: {},
    qdata: {},
  }},
  methods: {
    _showModal(){
      this.showModal = !this.showModal 
    },
    _submit(state){
      this.$req.wlzCommit({correct: Number(state)}, (res)=>{
        this.$store.commit('tipShow', res.question_text)
        if(state){
          this.initPlantAndQuestion(true)
        }
        this._showModal()
      })
    },
    initPlantAndQuestion(onlyPlant=false){
      this.$req.wlzInfo(res=>{
        this.showBtn = res.today_plant_num > 0
        this.plant = res.plant
        if(!onlyPlant){
          this.$req.wlzGetQuestion((res)=>{
            let {question_num, question_text} = res
            this.canAnswer = question_num > 0
            if(question_num){
              let q = res.questionArr[0]
              this.qdata.question = q.eqname
              let rightKey = []
              this.qdata.items = q.answer_list.map((a)=>{
                rightKey.push(a.is_right)
                return a.answer_con
              })
              this.qdata.rightKey = rightKey
            }
          })
        }
      })
    },
  },
  mounted(){
    this.initPlantAndQuestion()
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/func.less";
#ido-pei{
  article{
    .__spacing(padding, 15);
    line-height: 1.6;
    .__rem(2, letter-spacing);
    span.green{
      color:#1BB10C;
      display: inline-block;
      text-decoration: underline;
      .__rem(45, padding-top);
      .__rem(6, letter-spacing);
    }
  }
}
</style>